import React from 'react';
import Header from './components/Header';
import Features from './components/Features';
// import other components here

function App() {
  return (
    <>
      <header>
        <Header />
      </header>
      
      <main>
        <Features />
        {/* Add other components here */}
      </main>
      
      {/* If you have a Footer component, wrap it with <footer> */}
      {/* 
      <footer>
        <Footer />
      </footer>
      */}
    </>
  );
}

export default App;
