import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPalette, faRocket, faBusinessTime } from '@fortawesome/free-solid-svg-icons';

const Features = () => {
    return (
        <section className="bg-white py-24 px-4 md:px-8 flex flex-col items-center" id="features-section">
            <header className="text-center mb-16 w-full">
                <h2 className="text-5xl font-bold">What's New?</h2>
            </header>

            <main className="grid grid-cols-1 md:grid-cols-3 gap-12 w-full">

                {/* Spatial Commerce Unleashed */}
                <article className="bg-white p-8 rounded-lg shadow-lg hover:shadow-xl transition-transform transition-opacity duration-300 ease-in-out transform translate-y-4 opacity-0 appear">
                    <div className="flex justify-center mb-6">
                        <FontAwesomeIcon icon={faRocket} className="text-4xl transition-transform duration-300 ease-in-out transform hover:rotate-12" style={{ color: '#e37fc7' }} />
                    </div>
                    <h3 className="text-3xl font-semibold mb-4">Spatial Commerce Unleashed</h3>
                    <p>
                        Think ecommerce, but elevated. With freundX, every computing platform is a new frontier for immersive buying experiences.
                    </p>
                </article>

                {/* Expanding the Tech Palette: Curating Experiences */}
                <article className="bg-white p-8 rounded-lg shadow-lg hover:shadow-xl transition-transform transition-opacity duration-300 ease-in-out transform translate-y-4 opacity-0 appear">
                    <div className="flex justify-center mb-6">
                        <FontAwesomeIcon icon={faPalette} className="text-4xl transition-transform duration-300 ease-in-out transform hover:rotate-12" style={{ color: '#e37fc7' }} />
                    </div>
                    <h3 className="text-3xl font-semibold mb-4">Expanding the Tech Palette: Curating Experiences</h3>
                    <p>
                        From AR and VR to Generative AI, we're adding more dimensions to your customers' commerce experience. Staying ahead in tech, so you stay ahead in business.
                    </p>
                </article>

                {/* Transformative Business Models */}
                <article className="bg-white p-8 rounded-lg shadow-lg hover:shadow-xl transition-transform transition-opacity duration-300 ease-in-out transform translate-y-4 opacity-0 appear">
                    <div className="flex justify-center mb-6">
                        <FontAwesomeIcon icon={faBusinessTime} className="text-4xl transition-transform duration-300 ease-in-out transform hover:rotate-12" style={{ color: '#e37fc7' }} />
                    </div>
                    <h3 className="text-3xl font-semibold mb-4">Transformative Business Models</h3>
                    <p>
                        We're not just altering how your customers shop; we're revolutionizing how you do business. Adapt, scale, and innovate with freundX.
                    </p>
                </article>
            </main>

            <footer className="text-center mt-16 w-full flex flex-col justify-center items-center">
                <h3 className="text-4xl font-semibold mb-4">Get Onboard the Future</h3>
                <p className="mb-6 max-w-lg text-xl">
                    For inquiries and to learn more about our transformative journey into spatial commerce, reach out to us at <a href="mailto:hello@freundx.io" className="text-blue-500 underline hover:text-blue-700" title="Email us at hello@freundx.io">hello@freundx.io</a>.
                </p>
            </footer>
        </section>
    );
}

export default Features;
