import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import defaultBackground from '../Background/backland.png';
import logoImage from '../Logo/freundX_logo.png';

class TextScramble {
  constructor(el) {
    this.el = el;
    this.chars = '!<>-_\\/[]{}—=+*^?#________';
    this.update = this.update.bind(this);
  }

  setText(newText) {
    const oldText = this.el.innerText;
    const length = Math.max(oldText.length, newText.length);
    const promise = new Promise((resolve) => (this.resolve = resolve));
    this.queue = [];
    for (let i = 0; i < length; i++) {
      const from = oldText[i] || '';
      const to = newText[i] || '';
      const start = Math.floor(Math.random() * 20);
      const end = start + Math.floor(Math.random() * 20);
      this.queue.push({ from, to, start, end });
    }
    cancelAnimationFrame(this.frameRequest);
    this.frame = 0;
    this.update();
    return promise;
  }

  update() {
    let output = '';
    let complete = 0;
    for (let i = 0, n = this.queue.length; i < n; i++) {
      let { from, to, start, end, char } = this.queue[i];
      if (this.frame >= end) {
        complete++;
        output += to;
      } else if (this.frame >= start) {
        if (!char || Math.random() < 0.28) {
          char = this.randomChar();
          this.queue[i].char = char;
        }
        output += `<span>${char}</span>`;
      } else {
        output += from;
      }
    }
    this.el.innerHTML = output;
    if (complete === this.queue.length) {
      this.resolve();
    } else {
      this.frameRequest = requestAnimationFrame(this.update);
      this.frame++;
    }
  }

  randomChar() {
    return this.chars[Math.floor(Math.random() * this.chars.length)];
  }
}

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState(defaultBackground);
  const scrambleRef = useRef(null);

  useEffect(() => {

      gsap.from('.header-text', { duration: 2, autoAlpha: 1 });

    if (scrambleRef.current) {
      const fx = new TextScramble(scrambleRef.current);
      const phrases = ["We're Pivoting", "We're Rebranding", 'But Our Promise Remains'];
      let counter = 0;

      const next = () => {
        fx.setText(phrases[counter]).then(() => {
          setTimeout(next, 800);
        });
        counter = (counter + 1) % phrases.length;
      };

      next();
    }
  }, []);

  return (
    <header 
    className="text-white relative min-h-screen px-4 md:px-8"
      style={{ 
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    >
      <nav className="flex justify-between items-center p-4 md:p-8">
        <img src={logoImage} alt="freundX logo" className="h-15 w-40 hidden md:block" /> {/* <-- Logo here */}
        <button onClick={() => setIsNavOpen(!isNavOpen)} className={`text-white text-3xl transition-transform ease-out duration-300 ${isNavOpen ? 'rotate-45' : ''}`}>
          ☰
          </button>
      </nav>
      <aside className={`absolute top-0 right-0 w-full md:w-1/4 h-screen p-4 transform transition-transform ease-out duration-300 ${isNavOpen ? 'translate-x-0' : 'translate-x-full'}`}
  style={{ backgroundColor: 'rgba(77, 99, 216, 0.7)' }}
>
  <button onClick={() => setIsNavOpen(false)} className="text-white text-3xl">
    ✕
  </button>
  <ul className="mt-4 text-xl">
    <li><a href="#features-section">Explore Features</a></li>
    <li><a href="#features-section">Contact Us</a></li>
  </ul>
</aside>

        <div className="text-center py-12 md:py-20 xl:py-32 pb-32 xl:pb-48">
        <h1 className="text-6xl md:text-8xl lg:text-9xl xl:text-10xl header-text font-semibold text-green-400" style={{ fontFamily: 'Sofia Pro' }}>
          freundX
        </h1>
        <p className="text-xl md:text-2xl lg:text-3xl xl:text-4xl header-text mt-4 mb-4 font-semibold text-green-400" style={{ fontFamily: 'Sofia Pro' }}>
          Pioneering Spatial Commerce & Community Spaces of Tomorrow
        </p>
        <p ref={scrambleRef} className="text-lg md:text-xl lg:text-2xl mt-4 text-yellow-300"></p>

        <div className="text-center mt-8 text-base md:text-lg lg:text-xl text-white">
          Something exciting is happening at freundAR. As we embrace new technologies beyond AR, we're evolving into freundX. The "X" symbolizes the limitless possibilities in spatial commerce, building communities, and more. 
          <br />
          <br />
          Same <em>freundship</em>, New Dimensions
          <br />
          <br />
          Our name might be changing, but our commitment to groundbreaking experiences remains. In the world of spatial commerce, we'll always be your best <em>freund</em>.
        </div>
      </div>
      </header>
  );
};

export default Header;